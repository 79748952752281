.ar-box {
  width     : 100%;
  max-width : 100%;
  padding   : var(--ar-box-padding, 0);

  &__container {
    display     : block;
    position    : relative;
    padding-top : var(--verticalPadding, 100%);
    overflow    : hidden;

    &__content {
      display         : flex;
      position        : absolute;
      flex-flow       : column;
      justify-content : center;
      align-items     : center;
      max-height      : 100%;
      top             : 0;
      right           : 0;
      bottom          : 0;
      left            : 0;
      }
    }
  }

.ar-box-debug {
  .ar-box {
    &__container {
      outline        : 1px solid $colorFontInfo;
      outline-offset : -1px;
      }
    }
  }