@import "../../styles/variables";

.partners {
  min-width        : 0;
  max-width        : 100%;
  padding          : 40px 0 90px;
  background-color : $colorBackgroundContent;

  &__container {
    //display         : flex;
    //flex-flow       : row nowrap;
    //justify-content : center;
    //align-items     : stretch;
    //padding         : 0 20px;

    //@media only screen and (max-width : 900px) {flex-flow : column nowrap;}

    &__partners {
      display     : flex;
      flex-flow   : column;
      align-items : center;
      min-width   : 0;
      max-width   : 100%;
      padding     : 0 1rem 0 0;

      &__title {
        margin         : 0;
        padding        : 20px 0 50px;
        font-size      : 1.8rem;
        font-weight    : 700;
        text-align     : center;
        text-transform : uppercase;
        [data-theme=light] & {color : #53707d;}
        [data-theme=dark] & {color : #718b97;}
        }

      &__list {
        display          : flex;
        flex-flow        : row wrap;
        flex-grow        : 1;
        justify-content  : center;
        align-items      : center;
        width            : 100%;
        min-width        : 0;
        max-width        : 100%;
        margin           : 0;
        padding          : 0 0 1.5rem;
        list-style       : none;
        border-radius    : $borderRadiusContainerInnerEdge;
        background-color : $colorBackgroundContent;
        }
      }
    }
  }

.partner {
  min-width : 0;
  max-width : 100%;

  &__link {
    display          : flex;
    justify-content  : center;
    align-items      : center;
    width            : 100%;
    min-width        : 0;
    max-width        : 100%;
    border-radius    : $borderRadiusContainerInnerEdge;
    background-color : $colorBackgroundContent;

    &:focus-visible {
      outline        : 1px solid $colorFontInfo;
      outline-offset : -1px;
      }

    &__button {
      min-width : 0;
      max-width : 100%;

      .ripple-layout {
        width     : 100%;
        min-width : 0;
        max-width : 100%;
        }

      &__logo {
        display         : flex;
        justify-content : center;
        align-items     : center;
        width           : 100%;
        min-width       : 0;
        max-width       : 100%;
        height          : 100%;
        max-height      : 90px;
        margin          : 2rem 0;
        padding         : 20px 40px;

        &__image {
          min-width  : 0;
          max-width  : 210px;
          min-height : 0;
          max-height : 100%;
          }
        }
      }
    }
  }

