@import "src/styles/variables";
@import "src/styles/mixins";

.layout {

  &__layer {
    width     : 100%;
    max-width : var(--container-max-width, 600px);
    padding   : var(--layout-padding, 40px);
    }
  }

.container {
  border-radius    : var(--contentBorderRadius, $borderRadiusContainerOuter);
  background-color : var(--content-background-color-light, $colorBackgroundBasicLight);
  [data-theme=light] & {background-color : var(--content-background-color-light, $colorBackgroundBasicLight);}
  [data-theme=dark] & {background-color : var(--content-background-color-dark, $colorBackgroundBasicLight);}

  &--darker {
    [data-theme=light] & {background-color : var(--content-background-color-light, $colorBackgroundBasic);}
    [data-theme=dark] & {background-color : var(--content-background-color-dark, $colorBackgroundBasic);}
    }

  &__header {
    display     : flex;
    align-items : center;
    padding     : $paddingContainerOuter;

    &__button {
      width  : 4rem;
      height : 4rem;
      }

    &__title {
      padding-left  : 4px;
      overflow      : hidden;
      font-family   : $fontFamilyPrimary;
      font-size     : 1.5rem;
      font-weight   : $fontWeightPrimaryBold;
      line-height   : 1.2;
      white-space   : nowrap;
      text-overflow : ellipsis;
      }
    }

  &__content {
    padding-right  : calc(var(--paddingContainerOuter) / 2);
    padding-bottom : var(--content-padding-bottom, var(--paddingContainerOuter));
    padding-left   : $paddingContainerOuter;

    &__inner {
      max-height    : calc(100vh - 200px);
      padding-right : calc(var(--paddingContainerOuter) / 2);
      overflow      : auto;
      border-radius : $borderRadiusContainerInnerEdge;
      }
    }
  }