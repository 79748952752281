@import "../../styles/variables";

.clouds {
  padding          : 0 0 90px;
  background-color : $colorBackgroundBasicLight;

  &__container {
    padding : 0 2rem;

    &__content {
      display     : flex;
      flex-flow   : column;
      align-items : stretch;
      width       : 100%;

      > h2 {
        padding        : 40px 0;
        font-size      : 18px;
        font-weight    : 700;
        text-align     : center;
        text-transform : uppercase;
        [data-theme=light] & {color : #53707d;}
        [data-theme=dark] & {color : #718b97;}
        }

      &__pane {
        display         : flex;
        position        : relative;
        flex-flow       : row wrap;
        flex-flow       : row wrap;
        justify-content : center;
        align-items     : center;
        width           : calc(100% + 10px);
        min-height      : 200px;
        margin          : 0 -10px 0 0;
        padding         : 0;
        list-style      : none;
        }
      }
    }
  }

.cloud {
  width   : calc(100% / var(--provider-quantity, 6));
  padding : 0 10px 10px 0;
  cursor  : pointer;
  @media only screen and (max-width : 1000px) {width : calc(100% / 5)}
  @media only screen and (max-width : 840px) {width : calc(100% / 4)}
  @media only screen and (max-width : 660px) {width : calc(100% / 3)}
  @media only screen and (max-width : 540px) {width : calc(100% / 2)}

  &__container {
    display       : block;
    position      : relative;
    height        : 100%;
    padding-top   : 100%;
    overflow      : hidden;
    user-select   : none;
    transition    : $transitionDefault;
    border-radius : $borderRadiusContainerInnerEdge;

    &:focus-visible, &:focus-visible :local &__content {
      [data-theme=light] &, [data-theme=dark] & {
        transition     : 0s ease;
        outline        : 2px solid $colorFontInfo;
        outline-offset : -2px;
        }
      }

    &:hover, &:active {
      [data-theme=light] &, [data-theme=dark] & {
        transition : 0s ease;
        outline    : 1px solid transparent;
        }
      }

    &:hover :local &__content {
      transition       : $transitionTimingFunctionDefaultInverse 200ms;
      background-color : $colorBackgroundTop;
      [data-theme=light] & {
        outline        : 1px solid $colorFontInfo;
        outline-offset : -1px;
        }
      &__name {color : $colorFontInfo;}
      &__image-icon > img {opacity : 1;}
      }

    &:active :local &__content {
      [data-theme=light] &, [data-theme=dark] & {
        transition     : $transitionTimingFunctionDefault 50ms;
        outline        : 4px solid $colorBackgroundBasicLight;
        outline-offset : -4px;
        }
      }

    &__content {
      display          : flex;
      position         : absolute;
      flex-flow        : column;
      justify-content  : center;
      align-items      : center;
      padding          : 12px 7px 20px;
      top              : 0;
      right            : 0;
      bottom           : 0;
      left             : 0;
      transition       : $transitionDefault;
      border-radius    : $borderRadiusContainerInnerEdge;
      outline          : 1px solid transparent;
      outline-offset   : -1px;
      background-color : $colorBackgroundContent;

      &__image-icon {
        display         : flex;
        flex-grow       : 1;
        justify-content : center;
        align-items     : center;
        width           : 100%;

        > img {
          width      : 100%;
          max-width  : 55%;
          max-height : 60%;
          transition : $transitionDefault;
          }
        }

      &__img-upc > img {width : 72%}
      &__img-htz > img {width : 78%}
      &__img-own > img {width : 36%}
      &__img-ali > img {width : 50%}

      &__name {
        flex-shrink   : 0;
        min-width     : 0;
        max-width     : 100%;
        margin        : 0;
        padding       : 0 12px;
        overflow      : hidden;
        transition    : $transitionDefault;
        font-size     : 1.5rem;
        font-weight   : $fontWeightPrimaryRegular;
        line-height   : 1.5;
        white-space   : nowrap;
        text-overflow : ellipsis;
        color         : $colorFontCommon;
        @media only screen and (max-width : 310px) {font-size : 1.3rem;}
        }
      }

    &__status {
      display          : flex;
      position         : absolute;
      justify-content  : center;
      align-items      : center;
      width            : 3rem;
      height           : 3rem;
      top              : 10px;
      right            : 10px;
      font-size        : 1.4rem;
      color            : $colorFontCommon;
      border-radius    : 50%;
      background-color : #eaeaea;
      opacity          : 0.5;
      [data-theme=dark] & {background-color : $colorBackgroundBasic;}

      > svg {
        width  : 1.4rem;
        height : 1.4rem;
        }
      }
    }
  }

