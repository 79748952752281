@import '../../styles/variables';

.promo {
  display         : flex;
  position        : relative;
  justify-content : center;
  align-items     : center;
  padding         : 0;
  overflow        : hidden;
  box-shadow      : inset 0 -0.8rem 1.3rem rgba(0, 0, 0, 0.05);

  &__background {
    position              : absolute;
    top                   : 0;
    right                 : 0;
    bottom                : 0;
    left                  : 0;
    background-color      : $colorBackgroundContent;
    //background-image      : url("https://try.direct/img/homepage/promo_pic.svg");
    background-image      : var(--arrival-bg-image, url("https://try.direct/img/homepage/promo_pic.svg"));
    background-repeat     : no-repeat;
    background-attachment : fixed;
    background-position   : 0 40%;
    background-size       : 125%;
    opacity               : 0.6;

    @media only screen and (max-width : 1700px) {
      background-position : 200% center;
      background-size     : 90%;
      }

    @media only screen and (max-width : 1300px) {
      background-position : -100% -40%;
      background-size     : 110%;
      }

    @media only screen and (max-width : 1080px) {
      background-position : -60% -40%;
      background-size     : 120%;
      }

    @media only screen and (max-width : 950px) {
      background-position : -20% -40%;
      background-size     : 135%;
      }

    @media only screen and (max-width : 830px) {
      background-attachment : scroll;
      background-position   : center 100%;
      background-size       : 150%;
      }

    @media only screen and (max-width : 730px) {
      background-position : center center;
      background-size     : 180%;
      }

    @media only screen and (max-width : 615px) {
      background-position : center center;
      background-size     : 180%;
      }

    @media only screen and (max-width : 590px) {
      background-position : center center;
      background-size     : 320%;
      }
    }

  &__container {
    display         : flex;
    position        : relative;
    justify-content : flex-start;
    align-items     : center;
    max-width       : 1200px;
    padding         : 70px 20px;

    @media only screen and (max-width : 830px) {
      padding : 9rem 2rem;
      }

    @media only screen and (max-width : 490px) {
      //padding : 100px 0;
      }

    &__text-container {
      position : relative;
      z-index  : 1;

      @media only screen and (max-width : 830px) {
        max-width : 100%;
        }

      &__sticker {
        position : absolute;
        width    : 8rem;
        height   : calc(100% + 4rem);
        top      : -2rem;
        right    : 6rem;

        @media only screen and (max-width : 730px) {
          height : 12rem;
          }

        &__back {
          position         : absolute;
          width            : 100%;
          height           : 100%;
          border-radius    : 0 0.3rem 0.3rem 0;
          background-color : #cb4743;
          box-shadow       : 3px 3px 18px 6px rgba(0, 0, 0, 0.2);
          z-index          : 2;

          &::before {
            position         : absolute;
            content          : "";
            width            : calc(100% - 2rem);
            height           : calc(100% - 0.6rem);
            margin           : 0.3rem 1rem;
            border-right     : 0.2rem dashed #f18686;
            border-left      : 0.2rem dashed #f18686;
            background-color : transparent;
            }

          &__content {
            display          : flex;
            position         : absolute;
            justify-content  : center;
            align-items      : center;
            width            : 18rem;
            height           : 18rem;
            padding          : 3rem;
            top              : 8rem;
            left             : -5rem;
            font-family      : $fontFamilyPrimary;
            font-size        : 1.5em;
            font-weight      : 800;
            text-align       : center;
            text-transform   : uppercase;
            color            : #fff;
            border-radius    : 50%;
            background-color : #cb4743;
            box-shadow       : 3px 3px 14px 6px rgba(0, 0, 0, 0.2);

            @media only screen and (max-width : 730px) {
              width       : 12rem;
              height      : 12rem;
              top         : 2rem;
              left        : -2rem;
              font-size   : 1.5rem;
              line-height : 1.2;
              }

            &::before {
              position      : absolute;
              content       : "";
              width         : calc(100% - 2rem);
              height        : calc(100% - 2rem);
              transform     : rotate(180deg);
              border        : 0.2rem dashed #f1b1af;
              border-radius : 50%;
              }
            }
          }

        &::before {
          position      : absolute;
          content       : "";
          top           : 0;
          left          : -2.5rem;
          border-bottom : 2rem solid #993531;
          border-left   : 2.5rem solid transparent;
          z-index       : 1;
          }

        &::after {
          position    : absolute;
          content     : "";
          bottom      : 0;
          left        : -2.5rem;
          border-top  : 2rem solid #993531;
          border-left : 2.5rem solid transparent;
          z-index     : 1;

          @media only screen and (max-width : 730px) {
            display : none;
            }
          }
        }

      &__content {
        display          : flex;
        flex-flow        : column;
        justify-content  : space-between;
        align-self       : stretch;
        padding          : 6rem 23rem 6rem 6rem;
        border-radius    : $borderRadiusContainerInnerEdge;
        background-color : $colorBackgroundContent;
        box-shadow       : 3px 3px 34px 6px rgba(0, 0, 0, 0.09);
        z-index          : 2;

        @media only screen and (max-width : 1300px) {
          padding : 2.5rem 24rem 3.5rem 4.5rem;
          }

        @media only screen and (max-width : 730px) {
          padding : 3.5rem;
          }

        @media only screen and (max-width : 615px) {
          padding : 3.5rem;
          }

        @media only screen and (max-width : 490px) {
          padding : 15px 20px 35px;
          }

        &__button-container {
          display         : flex;
          flex-grow       : 2;
          justify-content : center;
          align-items     : flex-end;
          padding-top     : 2rem;

          @media only screen and (max-width : 1300px) {
            padding : 1rem 0;
            }

          @media only screen and (max-width : 730px) {
            padding-bottom : 0;
            }

          &__link {
            min-width     : 0;
            border-radius : 30px;

            :global .ripple-button {
              &__title {
                font-size      : 1.6rem;
                text-transform : initial;
                }
              }

            &:hover, &:focus {
              text-decoration : none;
              }
            }
          }

        > p {
          flex-grow   : 1;
          margin      : 0 0 2rem 0;
          font-family : $fontFamilyPrimary;
          font-size   : 1.6rem;
          font-weight : 600;
          line-height : 1.7;
          text-align  : left;
          color       : $colorFontCommon;

          > span {
            font-family : inherit;
            font-size   : inherit;
            font-weight : 600;
            }

          > a {
            font-family : inherit;
            font-size   : inherit;
            font-weight : 700;
            color       : $colorFontInfo;
            }
          }
        }
      }
    }
  }

.head {
  display         : flex;
  justify-content : flex-start;
  align-items     : center;
  margin          : 0 0 2rem 0;
  padding         : 0 0 1.5rem 0;
  border-bottom   : 0.1rem solid #ddd;
  [data-theme=dark] & {border-color : #404040}

  @media only screen and (max-width : 1300px) {
    margin : 0;
    border : none;
    }

  @media only screen and (max-width : 730px) {
    padding : 0 15rem 1.5rem 0;
    }

  @media only screen and (max-width : 615px) {
    padding-top : 2rem;
    }

  @media only screen and (max-width : 475px) {
    flex-flow   : column;
    align-items : flex-start;
    padding     : 1rem 0 1.5rem 0;
    }

  &__icon {
    display: flex;
    justify-content: center;
    align-items: center;
    order  : 1;
    width  : 8rem;
    height : 8rem;

    @media only screen and (max-width : 590px) {
      display : none;
      }

    @media only screen and (max-width : 475px) {
      display       : flex;
      margin-bottom : 2.5rem;
      }

    &__image {
      width     : 100%;
      max-width : 100%;
      }
    }

  > h2 {
    flex-grow      : 1;
    flex-shrink    : 0;
    order          : 2;
    margin         : 0;
    padding-left   : 10px;
    font-weight    : 600;
    text-transform : uppercase;

    @media only screen and (max-width : 830px) {
      flex-shrink : 1;
      }

    @media only screen and (max-width : 475px) {
      font-size   : 2rem;
      font-weight : 700;
      }
    }
  }