@import '../../styles/variables';

.carousel {
  position : relative;
  padding  : 0 10px 0 20px;

  &__title {
    margin         : 0;
    padding        : 40px 20px 40px;
    font-family    : $fontFamilyPrimary;
    font-size      : 18px;
    font-weight    : 700;
    text-align     : center;
    text-transform : uppercase;
    [data-theme=light] & {color : #53707d;}
    [data-theme=dark] & {color : #718b97;}
    }

  &__container {
    display         : flex;
    position        : relative;
    flex-flow       : row wrap;
    justify-content : center;
    align-items     : center;
    width           : 100%;
    padding-bottom  : 22px;
    }

  &__search {
    position : absolute;
    bottom   : -14px;
    z-index  : 2;
    }

  &::after {
    position         : absolute;
    content          : "";
    width            : 5.6rem;
    height           : 5.6rem;
    bottom           : -24px;
    border-radius    : 50%;
    background-color : $colorBackgroundBasicLight;
    z-index          : 1;
    }
  }

.appLayout {
  width          : calc(100% / 9);
  padding-right  : 10px;
  padding-bottom : 10px;

  @media only screen and (max-width : 945px) {width : calc(100% / 8);}
  @media only screen and (max-width : 870px) {width : calc(100% / 7);}
  @media only screen and (max-width : 770px) {width : calc(100% / 6);}
  @media only screen and (max-width : 665px) {width : calc(100% / 5);}
  @media only screen and (max-width : 560px) {width : calc(100% / 4);}
  @media only screen and (max-width : 450px) {width : calc(100% / 3);}
  @media only screen and (max-width : 345px) {width : calc(100% / 2);}
  @media only screen and (max-width : 240px) {width : calc(100% / 1);}

  &__container {
    display     : block;
    position    : relative;
    padding-top : 100%;
    overflow    : hidden;
    }
  }

.app {
  display          : flex;
  position         : absolute;
  flex-flow        : column;
  justify-content  : center;
  align-items      : center;
  width            : 100%;
  height           : 100%;
  max-height       : 100%;
  padding          : 0;
  top              : 0;
  right            : 0;
  bottom           : 0;
  left             : 0;
  cursor           : pointer;
  transition       : background-color $transitionDefault, 0s ease;
  border           : none;
  border-radius    : $borderRadiusContainerInnerEdge;
  background-color : $colorBackgroundContent;

  &:focus-visible {
    outline        : 1px solid $colorFontInfo;
    outline-offset : -1px;
    }

  &:active {outline : none;}

  &:hover {
    text-decoration  : none;
    background-color : $colorBackgroundTop;
    }

  &:hover &__name, &:focus &__name {
    opacity    : 1;
    visibility : visible;

    &::before {
      [data-theme=light] & {opacity : 0.5;}
      [data-theme=dark] & {opacity : 0.6;}
      }
    }

  &:hover &__container, &:focus &__container {filter : blur(5px);}

  &__container {
    display         : flex;
    position        : relative;
    justify-content : center;
    align-items     : center;
    width           : 100%;
    max-width       : 100%;
    height          : 100%;
    max-height      : 100%;
    padding         : 25px;
    overflow        : hidden;
    transition      : $transitionTimingFunctionDefault 100ms;

    &__image {
      display   : block;
      width     : 100%;
      max-width : 100%;
      }
    }

  &__name {
    display         : flex;
    position        : absolute;
    justify-content : center;
    align-items     : center;
    width           : 100%;
    height          : 100%;
    top             : 0;
    left            : 0;
    transition      : $transitionTimingFunctionDefault 100ms;
    opacity         : 0;
    visibility      : hidden;
    z-index         : 2;

    &__text {
      padding     : 15px;
      font-size   : 1.5rem;
      font-weight : 700;
      line-height : 18px;
      text-align  : center;
      color       : $colorFontTitle;
      z-index     : 2;
      }

    &::before {
      position      : absolute;
      content       : "";
      width         : calc(100% - 2px);
      height        : calc(100% - 2px);
      top           : 1px;
      left          : 1px;
      transition    : $transitionTimingFunctionDefault 100ms;
      border-radius : 25px;
      opacity       : 0;
      [data-theme=light] & {background-color : $colorBackgroundContent;}
      [data-theme=dark] & {background-color : $colorBackgroundTop;}
      }
    }

  &__additional {
    display         : flex;
    position        : absolute;
    justify-content : flex-end;
    width           : 100%;
    height          : 100%;
    top             : 5px;
    right           : 5px;
    pointer-events  : none;

    &__dev, &__formula {
      display         : flex;
      justify-content : center;
      align-items     : center;
      width           : 3rem;
      height          : 3rem;
      pointer-events  : auto;
      color           : $colorAccentBasic;
      border-radius   : 50%;

      & > i {
        display         : flex;
        justify-content : center;
        align-items     : center;
        font-size       : 2rem;

        > svg {
          width  : 2rem;
          height : 2rem;
          }
        }
      }
    &__dev {
      background-color : $colorBackgroundContent;
      }
    &__formula {
      margin-right : 5px;
      & > img {
        display : block;
        width   : auto;
        height  : 100%;
        }
      }
    }

  &__status {
    display          : flex;
    position         : absolute;
    justify-content  : center;
    align-items      : center;
    width            : 3rem;
    height           : 3rem;
    bottom           : 10px;
    left             : 10px;
    font-size        : 1.4rem;
    color            : $colorFontCommon;
    border-radius    : 50%;
    background-color : #eaeaea;
    opacity          : 0.5;
    [data-theme=dark] & {background-color : $colorBackgroundBasic;}

    > i {
      display         : flex;
      justify-content : center;
      align-items     : center;

      > svg {
        width  : 1.4rem;
        height : 1.4rem;
        }
      }
    }
  }