@mixin text-one-line() {
  overflow      : hidden;
  white-space   : nowrap;
  text-overflow : ellipsis;
  }
// === card
@mixin card(
  $padding: $paddingContainerOuter 0 $paddingContainerOuter 0,
  $background-color: $colorBackgroundBasicLight,
  $max-width: 100%,
  $border-radius: $borderRadiusContainerOuter,
  $display: flex,
  $flex-flow: column,
) {
  display          : $display;
  flex-flow        : $flex-flow;
  border-radius    : $border-radius;
  background-color : $background-color;
  padding          : $padding;
  max-width        : $max-width;
  }

@mixin card--darker(
  $background-color: $colorBackgroundBasic,
  $args...
) {
  @include card($args...);
  background-color : $background-color;
  }

@mixin card--success(
  $background-color-light: $colorBackgroundSuccess,
  $background-color-dark: $colorBackgroundSuccess,
) {
  [data-theme=light] & {background-color : $background-color-light;}
  [data-theme=dark] & {background-color : $background-color-dark;}
  }

@mixin card--warning(
  $background-color-light: $colorBackgroundWarning,
  $background-color-dark: $colorBackgroundWarning,
) {
  [data-theme=light] & {background-color : $background-color-light;}
  [data-theme=dark] & {background-color : $background-color-dark;}
  }

@mixin card--error(
  $background-color-light: $colorBackgroundError,
  $background-color-dark: $colorBackgroundError,
) {
  [data-theme=light] & {background-color : $background-color-light;}
  [data-theme=dark] & {background-color : $background-color-dark;}
  }

@mixin card-title(
  $color: $colorFontLight,
  $font-family: $fontFamilySecondary,
  $font-size: $fontSizeSecondarySmall,
  $font-weight: $fontWeightSecondaryRegular,
  $line-height: $lineHeightSecondarySmall,
  $padding: 8px calc(var(--paddingContainerOuter) + 20px),
  $span-font-size: $fontSizeSecondarySmall,
  $span-color: $colorFontLight,
  $span-font-weight: $fontWeightSecondaryThin,
) {
  padding     : $padding;
  font-size   : $font-size;
  font-weight : $font-weight;
  line-height : $line-height;
  font-family : $font-family;
  color       : $color;

  & > span {
    font-size   : $span-font-size;
    color       : $span-color;
    font-weight : $span-font-weight;
    }
  }

@mixin card-messages(
  $line-height: 1.4,
  $font-family: $fontFamilySecondary,
  $font-size: 1.3rem,
  $font-weight: $fontWeightSecondaryBold,
  $padding: 0 calc(var(--paddingContainerOuter) + 20px) 10px,
) {
  padding     : $padding;
  font-size   : $font-size;
  font-weight : $font-weight;
  line-height : $line-height;
  font-family : $font-family;

  & p, div, a, span {
    font-size   : inherit;
    font-weight : inherit;
    line-height : inherit;
    font-family : inherit;
    }
  }

@mixin card-container(
  $padding: 0 calc(var(--paddingContainerOuter) / 2) 0 $paddingContainerOuter,
  $display: flex,
  $flex-flow: column,
  $flex-grow: 1,
) {
  flex-grow : $flex-grow;
  display   : $display;
  flex-flow : $flex-flow;
  padding   : $padding;
  }

@mixin card-content(
  $padding: 0 calc(var(--paddingContainerOuter) / 2) 0 0,
  $display: flex,
  $flex-flow: column,
  $flex-grow: 1,
) {
  flex-grow : $flex-grow;
  display   : $display;
  flex-flow : $flex-flow;
  padding   : $padding;
  }

@mixin card-item(
  $padding: 15px 20px,
  $background-color: $colorBackgroundContent,
  $border-radius-inner: $borderRadiusContainerInner,
  $border-radius-outer: $borderRadiusContainerInnerEdge,
  $margin-bottom: $marginContainerInner,
  $line-height: 1.4,
  $font-family: $fontFamilySecondary,
  $font-size: 1.5rem,
  $font-weight: $fontWeightSecondaryBold,
) {
  flex-grow        : 1;
  padding          : $padding;
  border-radius    : $border-radius-inner;
  background-color : $background-color;
  margin-bottom    : $margin-bottom;
  line-height      : $line-height;
  font-family      : $font-family;
  font-size        : $font-size;
  font-weight      : $font-weight;

  &:first-child {
    border-radius : $border-radius-outer $border-radius-outer $border-radius-inner $border-radius-inner;
    }
  &:last-child {
    margin-bottom : 0;
    border-radius : $border-radius-inner $border-radius-inner $border-radius-outer $border-radius-outer;
    }
  &:only-child {
    margin-bottom : 0;
    border-radius : $border-radius-outer;
    }
  }

@mixin card-item--info(
  $outline: 1px dashed,
  $outline-color: $colorFontInfo,
  $outline-offset: -1px,
) {
  outline        : $outline $outline-color;
  outline-offset : $outline-offset;
  }

@mixin card-item--success(
  $outline: 1px dashed,
  $outline-color: $colorFontSuccess,
  $outline-offset: -1px,
) {
  outline        : $outline $outline-color;
  outline-offset : $outline-offset;
  }

@mixin card-item--warning(
  $outline: 1px dashed,
  $outline-color: $colorFontWarning,
  $outline-offset: -1px,
) {
  outline        : $outline $outline-color;
  outline-offset : $outline-offset;
  }

@mixin card-item--error(
  $outline: 1px dashed,
  $outline-color: $colorFontError,
  $outline-offset: -1px,
) {
  outline        : $outline $outline-color;
  outline-offset : $outline-offset;
  }

@mixin card-item-not-contained(
  $padding: 15px calc(var(--paddingContainerOuter) + 20px),
  $margin: 0 calc(0px - var(--paddingContainerOuter)),
  $line-height: 1.4,
  $font-family: $fontFamilySecondary,
  $font-size: 1.5rem,
  $font-weight: $fontWeightSecondaryBold,
) {
  padding     : $padding;
  margin      : $margin;
  line-height : $line-height;
  font-family : $font-family;
  font-size   : $font-size;
  font-weight : $font-weight;
  }

@mixin card-button(
  $border-radius: inherit,
  $height: auto,
  $padding: 15px 5px,
  $paddingText: 0 15px,
  $paddingIcon: 15px,
  $text-align: left,
  $text-transform: inherit,
  $hoverOpacity: 0.2,
  $line-height: 1.4,
  //$color: $colorAccentBasic,
  $font-family: $fontFamilySecondary,
  $font-size: 1.5rem,
  $font-weight: $fontWeightSecondaryBold,
  $widthIcon: 3rem,
) {
  border-radius : $border-radius;
  height        : $height;

  & .ripple-button {
    border-radius : inherit;
    padding       : $padding;
    height        : $height;

    &:hover::before {opacity : $hoverOpacity;}

    &__title {
      text-align     : $text-align;
      padding        : $paddingText;
      text-transform : $text-transform;
      font-family    : $font-family;
      font-size      : $font-size;
      font-weight    : $font-weight;
      line-height    : $line-height;
      //color          : $color,
      }
    &__icon {
      font-size       : $font-size;
      width           : $widthIcon;
      display         : flex;
      justify-content : center;
      align-items     : center;
      }
    &__icon-left {padding-left : $paddingIcon;}
    &__icon-right {padding-right : $paddingIcon;}
    }
  }

@mixin card-button-global($args...) {
  & :global {
    @include card-button($args...);
    }
  }

@mixin card-ripple(//$border-radius: inherit,
  //$height: auto,
  //$padding: 15px 10px,
  //$paddingInner: 10px,
  //$text-align: left,
  //$text-transform: inherit,
  //$hoverOpacity: 0.2,
  //$line-height: 1.4,
  //$font-family: $fontFamilySecondary,
  //$font-size: 1.5rem,
  //$font-weight: $fontWeightSecondaryBold,
) {
  //border-radius : $border-radius;
  //height        : $height;

  //& :global .ripple-button {
  //  border-radius : $border-radius;
  //  padding       : $padding;
  //  height        : $height;
  //
  //  &:hover::before {opacity : $hoverOpacity;}
  //
  //  &__title {
  //    text-align     : $text-align;
  //    padding        : 0 $paddingInner;
  //    text-transform : $text-transform;
  //    font-family    : $font-family;
  //    font-size      : $font-size;
  //    font-weight    : $font-weight;
  //    line-height    : $line-height;
  //    }
  //  &__icon-left {padding-left : $paddingInner;}
  //  &__icon-right {padding-right : $paddingInner;}
  //  }
  }

@mixin interactive-container(
  $outline-width: 1px,
  $outline-offset: -1px,
  $transition: $transitionDefault,
) {
  outline        : $outline-width solid transparent;
  outline-offset : $outline-offset;
  cursor         : pointer;
  transition     : $transition;

  &:hover, &:focus-visible {
    [data-theme=light] & {outline : $outline-width dashed $colorFontInfo;}
    [data-theme=dark] & {background-color : $colorBackgroundTop;}
    }

  &:active {
    [data-theme=light] & {
      outline          : $outline-width solid $colorBackgroundInfo;
      background-color : $colorBackgroundBasic;
      }
    [data-theme=dark] & {background-color : $colorBackgroundBasic;}
    }
  }