.button-scroll-top {
  position      : fixed;
  width         : 65px;
  height        : 65px;
  margin        : 0;
  right         : 20px;
  bottom        : 90px;
  transition    : .5s ease 0s, opacity .2s ease .1s;
  transform     : scale(0);
  border-radius : 50%;
  opacity       : 0;
  visibility    : hidden;
  z-index       : 990;

  &--visible {
    transition : .5s ease .0s, opacity .2s ease .0s;
    transform  : scale(1);
    opacity    : 0.2;
    visibility : visible;

    &:hover {opacity : 1;}
    }

  &--hidden {
    transition : .5s ease .0s, opacity .2s ease .0s;
    transform  : scale(0.3);
    opacity    : 0;
    visibility : hidden;
    }
  }

@media only screen and (max-width : 830px) {
  .button-scroll-top {
    &--higher {bottom : calc(9rem + 6.5rem + 0.5rem)}
    }
  }