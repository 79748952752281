//  RULES
//  ${elementProperty}{ElementCategory}{ElementType} : property-value || another-variable
//
// === THEME VARIABLES ===
$colorAccentSoft                        : var(--colorAccentSoft, #d1c4e9);
$colorAccentLight                       : var(--colorAccentLight, #9575cd);
$colorAccentBasic                       : var(--colorAccentBasic, #7e57c2);
$colorAccentContrast                    : var(--colorAccentContrast, #673ab7);

$colorBackgroundBasic                   : var(--colorBackgroundBasic, #ededed);
$colorBackgroundBasicLight              : var(--colorBackgroundBasicLight, #e6e6e6);
$colorBackgroundContent                 : var(--colorBackgroundContent, #fff);
$colorBackgroundTop                     : var(--colorBackgroundTop, #fff);

$colorBackgroundSuccess                 : var(--colorBackgroundSuccess, #ededed);
$colorBackgroundWarning                 : var(--colorBackgroundWarning, #ffe4d1);
$colorBackgroundError                   : var(--colorBackgroundError, #ffe7e7);
$colorBackgroundInfo                    : var(--colorBackgroundInfo, #eaf1fb);
$colorBackgroundDisabled                : var(--colorBackgroundDisabled, #e2e2e2);

$colorFontDisabled                      : var(--colorFontDisabled, #9b9b9b);
$colorFontLight                         : var(--colorFontLight, #757575);
$colorFontCommon                        : var(--colorFontCommon, #585858);
$colorFontTitle                         : var(--colorFontTitle, #333);
$colorFontContrast                      : var(--colorFontContrast, #202020);

$colorFontSuccess                       : var(--colorFontSuccess, #26a69a);
$colorFontWarning                       : var(--colorFontWarning, #ff8f00);
$colorFontError                         : var(--colorFontError, #ef5350);
$colorFontInfo                          : var(--colorFontInfo, #146fbe);
//
// === CONTAINERS ===
$paddingPage                            : var(--paddingPage, 20px);
// outer
$borderRadiusContainerOuter             : var(--borderRadiusContainerOuter, 28px);
$paddingContainerOuter                  : var(--paddingContainerOuter, 8px);
// inner
$borderRadiusContainerInnerEdge         : var(--borderRadiusContainerInnerEdge, 24px);
$borderRadiusContainerInner             : var(--borderRadiusContainerInner, 4px);
$paddingContainerInner                  : var(--paddingContainerInner, 20px);
$marginContainerInner                   : var(--marginContainerInner, 2px);
// block
$borderRadiusBlock                      : var(--borderRadiusBlock, 15px);
//$paddingBlock                           : var(--paddingBlock, 20px);
//$marginBlock                            : var(--marginBlock, 10px);
//
// ===  ANIMATIONS  ===
$transitionTimingFunctionDefault        : var(--transitionTimingFunctionDefault, cubic-bezier(.44, .44, .19, .68));
$transitionTimingFunctionDefaultInverse : var(--transitionTimingFunctionDefaultInverse, cubic-bezier(.68, .19, .44, .44));
$transitionDurationDefault              : var(--transitionDurationDefault, 200ms);
$transitionDefault                      : var(--transitionDurationDefault, 200ms) var(--transitionTimingFunctionDefault, cubic-bezier(.44, .44, .19, .68));
//
// ===  FONTS  ===
// primary
//$fontFamilyPrimary                      : var(--fontFamilyPrimary, 'Open Sans'), sans-serif;
$fontFamilyPrimary                      : var(--font-open-sans);
//
$fontSizePrimaryTiny                    : var(--fontSizePrimaryTiny, 1.2rem);
$fontSizePrimarySmall                   : var(--fontSizePrimarySmall, 1.4rem);
$fontSizePrimary                        : var(--fontSizePrimary, 1.6rem);
$fontSizePrimaryBig                     : var(--fontSizePrimaryBig, 1.8rem);
$fontSizePrimaryLarge                   : var(--fontSizePrimaryLarge, 2rem);
//
$lineHeightPrimaryTiny                  : var(--lineHeightPrimaryTiny, 1.2);
$lineHeightPrimarySmall                 : var(--lineHeightPrimarySmall, 1.4);
$lineHeightPrimary                      : var(--lineHeightPrimary, 1.6);
$lineHeightPrimaryBig                   : var(--lineHeightPrimaryBig, 1.8);
$lineHeightPrimaryLarge                 : var(--lineHeightPrimaryLarge, 2);
//
$fontWeightPrimaryThin                  : var(--fontWeightPrimaryThin, 400);
$fontWeightPrimaryRegular               : var(--fontWeightPrimaryRegular, 600);
$fontWeightPrimaryBold                  : var(--fontWeightPrimaryBold, 700);
$fontWeightPrimaryBoldExtra             : var(--fontWeightPrimaryBoldExtra, 700);
// secondary
//$fontFamilySecondary                    : var(--fontFamilySecondary, 'Roboto'), sans-serif;
$fontFamilySecondary                    : var(--font-roboto);
//
$fontSizeSecondaryTiny                  : var(--fontSizeSecondaryTiny, 1.1rem);
$fontSizeSecondarySmall                 : var(--fontSizeSecondarySmall, 1.3rem);
$fontSizeSecondary                      : var(--fontSizeSecondary, 1.5rem);
$fontSizeSecondaryBig                   : var(--fontSizeSecondaryBig, 1.7rem);
$fontSizeSecondaryLarge                 : var(--fontSizeSecondaryLarge, 1.9rem);
//
$lineHeightSecondaryTiny                : var(--lineHeightSecondaryTiny, 1.1);
$lineHeightSecondarySmall               : var(--lineHeightSecondarySmall, 1.3);
$lineHeightSecondary                    : var(--lineHeightSecondary, 1.5);
$lineHeightSecondaryBig                 : var(--lineHeightSecondaryBig, 1.7);
$lineHeightSecondaryLarge               : var(--lineHeightSecondaryLarge, 1.9);
//
$fontWeightSecondaryThin                : var(--fontWeightSecondaryThin, 300);
$fontWeightSecondaryRegular             : var(--fontWeightSecondaryRegular, 400);
$fontWeightSecondaryBold                : var(--fontWeightSecondaryBold, 500);
$fontWeightSecondaryBoldExtra           : var(--fontWeightSecondaryBoldExtra, 700);