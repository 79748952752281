@import "src/styles/variables";
@import "src/styles/mixins";

.layout {
  [data-theme=light] & {background-color : $colorBackgroundContent;}
  [data-theme=dark] & {background-color : $colorAccentSoft;}
  }

.content {
  display   : flex;
  flex-flow : row;

  @media only screen and (max-width : 830px) {
    flex-flow : column;
    }

  &__text {
    width         : 100%;
    max-width     : 50%;
    padding-right : 10px;

    @media only screen and (max-width : 830px) {
      max-width      : 100%;
      padding-bottom : 20px;
      text-align     : center;

      * {
        text-align : center;
        }
      }
    }

  &__image {
    position      : relative;
    width         : 100%;
    max-width     : 50%;
    margin-left   : 10px;
    overflow      : hidden;
    border-radius : $borderRadiusContainerInnerEdge;

    @media only screen and (max-width : 830px) {
      width       : auto;
      max-width   : none;
      margin-left : 0;
      }

    &:hover &__debug {
      display : block;
      }

    &__debug {
      display  : none;
      position : absolute;
      top      : 20px;
      left     : 20px;
      color    : $colorAccentBasic;
      z-index  : 1;
      }
    }
  }

.blocksLayout {
  position : relative;

  &::after {
    position         : absolute;
    content          : "";
    width            : 100%;
    height           : calc(100% - 6.1rem);
    bottom           : 0;
    //background-color : $colorAccentBasic;
    z-index          : 0;
    background : $colorAccentBasic;
    background : linear-gradient(160deg, $colorAccentContrast 0%, $colorAccentBasic 50%, $colorAccentLight 100%);

    @media only screen and (max-width : 684px) {
      height : calc(100% - 12.2rem);
      bottom : 6.1rem;
      }
    }
  }

.blocks {
  padding : 0 20px 40px;

  @media only screen and (max-width : 684px) {
    padding : 0 20px;
    }

  &__container {
    display      : flex;
    flex-flow    : row nowrap;
    align-items  : stretch;
    width        : calc(100% + 10px);
    margin-right : -10px;
    z-index      : 1;

    @media only screen and (max-width : 684px) {
      width        : 100%;
      margin-right : 0;
      }

    @media only screen and (max-width : 684px) {
      flex-flow : column;
      }

    &__item {
      width         : 100%;
      max-width     : var(--first-block-max-width);
      padding-right : 10px;

      @media only screen and (max-width : 684px) {
        max-width      : 100%;
        padding-right  : 0;
        padding-bottom : 10px;
        }
      }
    }
  }

.block {
  width            : 100%;
  height           : 100%;
  margin-right     : 10px;
  padding          : 25px 30px;
  border-radius    : $borderRadiusContainerInnerEdge;
  background-color : $colorBackgroundContent;

  [data-theme=light] & {
    box-shadow : 0 0 3px rgba(0, 0, 0, .06), 0 0 10px rgba(0, 0, 0, .05);


    @media only screen and (max-width : 684px) {
      box-shadow : 0 0 3px rgba(0, 0, 0, 0.15), 0 0 5px rgba(0, 0, 0, .08);
      }
    }

  &__head {
    display     : flex;
    align-items : center;

    &__icon {
      display         : flex;
      flex-shrink     : 0;
      justify-content : center;
      align-items     : center;
      height          : 2.6rem;

      &__image {
        max-width  : 100%;
        height     : 100%;
        max-height : 100%;
        color      : $colorAccentBasic;
        }
      }

    &__title {
      @include text-one-line;
      padding-left : 10px;
      font-family  : $fontFamilySecondary;
      font-size    : $fontSizeSecondaryLarge;
      font-weight  : $fontWeightSecondaryBoldExtra;
      color        : $colorAccentContrast;
      }
    }

  &__content {
    padding-top : 20px;
    font-family : $fontFamilySecondary;
    font-size   : $fontSizeSecondaryBig;
    font-weight : $fontWeightSecondaryRegular;
    line-height : $lineHeightSecondary;
    color       : $colorFontTitle;
    }
  }