@import "../../styles/variables";
@import "../../components/AspectRatioBox";

.layout {
  padding : 40px 20px 90px;
  }

.title {
  padding        : 20px 0 50px;
  font-size      : 1.8rem;
  font-weight    : 700;
  text-align     : center;
  text-transform : uppercase;
  [data-theme=light] & {color : #53707d;}
  [data-theme=dark] & {color : #718b97;}


  //@media only screen and (max-width : 490px) {
  //  padding        : 40px 0 40px;
  //  font-size: 2.5rem;
  //  font-family: $fontFamilySecondary;
  //  font-weight: $fontWeightSecondaryBold;
  //  [data-theme=light] & {color : $colorFontTitle;}
  //  [data-theme=dark] & {color : $colorFontTitle;}
  //  }
  }

.content {
  display         : flex;
  flex-flow       : row wrap;
  justify-content : flex-start;
  width           : calc(100% + 10px);
  margin          : auto auto -10px auto;
  padding         : 0;
  overflow        : hidden;
  list-style      : none;

  &__item {
    width   : calc(100% / 4);
    margin  : 0;
    padding : 0;

    @media only screen and (max-width : 930px) {width : calc(100% / 3);}
    @media only screen and (max-width : 720px) {width : calc(100% / 2);}
    @media only screen and (max-width : 490px) {
      width : calc(100% / 1);
      }
    }
  }

.category {
  display         : flex;
  flex-flow       : column;
  justify-content : center;
  align-items     : stretch;
  padding         : 0 10px 10px 0;
  transition      : $transitionDefault;
  border-radius   : $borderRadiusContainerInnerEdge;
  box-shadow      : none;

  @media only screen and (max-width : 490px) {
    padding : 0 10px 10px 0;
    & > div {--verticalPadding : 25%;}
    }

  &__container {
    width          : 100%;
    height         : 100%;
    border-radius  : $borderRadiusContainerInnerEdge;
    outline        : 1px solid;
    outline-offset : -1px;
    [data-theme=light] & {outline-color : #e0e0e0}
    [data-theme=dark] & {outline-color : #383838}

    &:focus-visible {outline-color : var(--category-outline-color, var(--rippleLayoutColorBasic));}
    &:focus-visible &__ripple :global .ripple-layout::before {
      background-color : var(--category-outline-color, var(--rippleLayoutColorBasic));
      opacity          : 0.1;
      }
    &:hover {
      transition      : 0s ease, outline-color $transitionDefault;
      text-decoration : none;
      outline-color   : var(--category-outline-color, var(--rippleLayoutColorBasic));
      }
    &:active {outline-color : transparent;}

    &__ripple {
      :global .ripple-layout {
        display         : flex;
        flex-flow       : column;
        justify-content : center;
        align-items     : center;
        transition      : 0s ease;
        outline         : none;

        @media only screen and (max-width : 490px) {
          flex-flow : row;
          padding   : 0 20px;
          }
        }
      }

    &__icon {
      flex-shrink : 1;
      min-width   : 0;
      max-width   : 60px;
      min-height  : 0;
      max-height  : 60px;

      @media only screen and (max-width : 490px) {max-width : 12%;}

      &__image {
        display    : block;
        align-self : center;
        width      : 100%;
        min-width  : 0;
        height     : 100%;
        min-height : 0;
        [data-theme=dark] & {opacity : 0.8}
        }
      }

    &__title {
      display       : block;
      width         : 100%;
      margin        : 0;
      padding       : 20px 20px 0;
      overflow      : hidden;
      font-size     : 1.5rem;
      font-weight   : 600;
      line-height   : 1.3;
      text-align    : center;
      white-space   : nowrap;
      text-overflow : ellipsis;
      color         : $colorFontCommon;


      @media only screen and (max-width : 490px) {
        padding     : 0 0 0 20px;
        font-family : $fontFamilySecondary;
        font-size   : 2.2rem;
        font-weight : $fontWeightSecondaryRegular;
        line-height : 1.2;
        text-align  : left;
        white-space : pre-line;
        color       : $colorFontTitle;
        }

      @media only screen and (max-width : 310px) {
        padding   : 0 0 0 10px;
        font-size : 1.8rem;
        }
      }
    }
  }