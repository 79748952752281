.layout-full {
  display     : flex;
  flex-flow   : column;
  flex-grow   : 1;
  width       : 100%;
  padding-top : 70px;

  @media only screen and (max-width : 830px) {
    padding-top : 60px;
    }

  [data-device=mobile] & {padding-top : 60px;}

  &__container {
    display   : flex;
    flex-flow : column;
    flex-grow : 1;
    width     : 100%;
    height    : 100%;
    padding   : 0;
    }
  }
